import React from "react"
import styles from "./person-name.module.scss"

const PersonName = ({children}) => {

	return (
		<p className={styles.person}>{children}</p>
	)
}


export default PersonName

