import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react"
import JobTitle from "../job-title/job-title";
import PersonName from "../person-name/person-name";
import PhotoCredit from "../photo-credit/photo-credit";
import Quote from "../quote/quote";
import ThankYou from "../thankyou/thankyou";
import Image from "../image/image";
import { getSizeFor } from "../../globals/ImageSize";
import TextBlock from "../text-block/text-block";
import {gsap, Power1} from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './volunteer.scss';
import ArrowScroll from "../arrow-scroll/arrow-scroll";
import Spacer from "../spacer/spacer";
import {useWindowWidth} from '@react-hook/window-size'
import useSize from '../../hooks/useSize';
gsap.registerPlugin(ScrollTrigger);

const Volunteer = ({active}) => {

	/**
	 * 
	 * This should be a HOC as base, wrapper, progress are the same across all 4 sections
	 * as well as all refs, unfortunately there is no time to develop and debug it :()
	 */

	const base = useRef();
	const wrapper = useRef(null);
	const progressBar = useRef();
	const timeline = useRef();
	const sectionScrollBar = useRef();
	const thankRef = useRef();
	const width = useWindowWidth()

	const [imageStyles, setStyles] = useState({});
	useEffect(() => {
		let styles = {}

		if(width >= 1100) {
			styles = {
				image1: {width:getSizeFor(1780, 1544, 1).width},
				image2: {width: getSizeFor(1102, 735, 0.5).width},
				image3: {width: getSizeFor(1102, 735, 0.5).width},
				image4: {width:getSizeFor(1152, 768, 0.501).width},
				image5: {width:getSizeFor(1152, 768, 0.501).width},
				image6: {width:getSizeFor(2132, 1394, 1).width},
				image7: getSizeFor(1472, 981, 0.65),
				image8: getSizeFor(928, 620, 0.35),
				image9: {width:getSizeFor(1265, 828, 0.55).width},
				image10: {width: getSizeFor(1067, 697, 0.5).width},
				image11: {width: getSizeFor(1066, 698, 0.5).width},
				image12: {width:getSizeFor(2547, 1546, 1).width},
			}
		}

		setStyles(styles);
	}, [width])


	const size = useSize(wrapper);
	useLayoutEffect(() => {
		if(!size || !timeline.current) return;
		ScrollTrigger.refresh(true);
	}, [size])


	const fade1 = useRef();
	const fade2 = useRef();
	const fade3 = useRef();
	const fade4 = useRef();
	const fade5 = useRef();
	const fade6 = useRef();
	const fade7 = useRef();

	const image1 = useRef();
	const image2 = useRef();
	const image3 = useRef();
	const image4 = useRef();
	const image5 = useRef();
	const image6 = useRef();
	const image7 = useRef();
	const image8 = useRef();
	const image9 = useRef();
	const image10 = useRef();
	const image11 = useRef();
	const image12 = useRef();


	const quote0 = useRef();
	const quote1 = useRef();
	const quote2 = useRef();
	const [quotes, setQuotes] = useState([])
	const [thanks, setThanks] = useState(false)

	const onActiveQuote = useCallback((index) => {
		const q = [...quotes];
		q[index] = true;
		setQuotes(q);
	}, [quotes])


	useEffect(() => {
		if(!wrapper.current) return;

		if(timeline.current && !active && size && width){

			const x = sectionScrollBar.current.scrollLeft;
			const w = size.width - width;
			const p = x / w;
			const t = (5000 * p) + 500;
			sectionScrollBar.current.scrollTo(0, 0, t);
			
			setQuotes([]);
		}
	}, [active, wrapper, size, width]);

	useLayoutEffect(() => {
		if(!imageStyles.image1 || timeline.current) {
			return;
		}

		sectionScrollBar.current = Scrollbar.init(base.current,
			{	damping: 0.15,
				// delegateTo: document
			});
		sectionScrollBar.current.setPosition(0, 0);
		sectionScrollBar.current.track.yAxis.element.remove();

		ScrollTrigger.scrollerProxy(base.current, {
			scrollLeft(value) {
				// console.log(`🚀 ~ file: base-section.js ~ line 58 ~ scrollLeft ~ value`, sectionScrollBar.current.scrollLeft);
				
                if (arguments.length) {
					sectionScrollBar.current.scrollLeft = value;
				}
				return sectionScrollBar.current.scrollLeft;
			}
		});

		timeline.current = gsap.timeline({
			scrollTrigger: {
				scroller: base.current,
				trigger: wrapper.current,
				horizontal: true,
				scrub: 0,
				// pin: true,
				// markers: true,
				start: "0",
				end: "right right",
				onUpdate: ({progress, direction, isActive}) => {
					// console.log(progress, direction, isActive)
				}
			}
		}).to(progressBar.current, {scaleX: 1, ease: "none"}, 0);


		const fadeins = [fade1.current, fade2.current, fade3.current, fade4.current, fade5.current, fade6.current, fade7.current];
		fadeins.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 75%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				ease: Power1.easeOut
			});
		});
		
		const slides = [image1.current, image2.current, image3.current, image6.current, image8.current, image9.current, image11.current, image12.current];
		slides.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				x: 100,
				ease: Power1.easeOut
			});
		});

		const slideTop = [image4.current, image7.current, image10.current];
		slideTop.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				y: -30,
				ease: Power1.easeOut
			});
		});

		gsap.from(image5.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: image5.current,
				toggleActions: "restart none none reverse",
				start: "left 90%",
				// markers: true,
				horizontal: true,
				// scrub: 1
			},
			duration: 1.25,
			alpha: 0, 
			y: 30,
			ease: Power1.easeOut
		});


		const quotesArray = [quote0.current, quote1.current, quote2.current];
		quotesArray.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 60%",
					// markers: true,
					horizontal: true,
					// scrub: 1
					onEnter: ({progress, direction, isActive}) => {
						onActiveQuote(index);
					}
				},
				duration: 0,
			});
		});

		gsap.from(thankRef.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: thankRef.current,
				toggleActions: "restart none none reverse",
				start: "left 25%",
				horizontal: true,
				onEnter: ({progress, direction, isActive}) => {
					setThanks(true);
				}
			},
			duration: 0,
		});

		

		sectionScrollBar.current.addListener(ScrollTrigger.update);

		return () => {
			setQuotes([])
		}

	}, [onActiveQuote, imageStyles])



	
	return (

		<section className="base" ref={base}>
			<div className="wrapper" ref={wrapper}>


					{/* Content */}
					

					<div className="volunteer">
						
						<Spacer />
						
						<div className="col col-50" ref={fade1}>
							<PersonName>Asli Ucyigit</PersonName>
							<hr />
							<JobTitle>LEAD CONSULTANT STRATEGY,<br/>EQUINOR</JobTitle>
							<p className="extra-margin-bottom">Asli spearheads the volunteering efforts at Equinor UK, with a focus on supporting local communities around the Paddington area. She has been volunteering since University and is a strong believer in equal opportunities and the importance of giving back to the community.</p>
							<PhotoCredit>Photography by Sean Pollock</PhotoCredit>
							<ArrowScroll x={150} />
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image1} ref={image1}>
							<Image src="/images/asli/001.jpg" alt="Asli Ucyigit"/>
						</div>

						{/* <div className="col col-50 mobile" ref={fade1}>
							<Spacer />
							<PersonName>Asli Ucyigit</PersonName>
							<hr />
							<JobTitle>LEAD CONSULTANT STRATEGY,<br/>EQUINOR</JobTitle>
							<p className="extra-margin-bottom">Asli spearheads the volunteering efforts at Equinor UK, with a focus on supporting local communities around the Paddington area. She has been volunteering since University and is a strong believer in equal opportunities and the importance of giving back to the community.</p>
							<PhotoCredit>Photography by Sean Pollock</PhotoCredit>
							<ArrowScroll x={150} />
						</div> */}

						<Spacer />

						<div className="col col-70" ref={fade2}>
							<TextBlock>
								<h2>How and why did you get involved with volunteering?</h2>
								<p>I have always been involved in some form of volunteering since University, participating in local community projects focused on people with learning difficulties and mentoring at local schools in Bristol, to post-University where I was a mentor for secondary school pupils, and now at Equinor. It’s a fantastic way to meet great people and give back to the community by supporting others who may need it.</p>
								<p>I am a strong believer in equal opportunities and do what I can as an individual to promote that.</p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col">
							<div className="row">
								<Spacer desktop />
								<div style={imageStyles.image2} ref={image2}>
									<Image src="/images/asli/002.jpg" alt="Asli Ucyigit"/>
								</div>
								<div className="col photo2 desktop">
									<PhotoCredit position="Left">Asli at North Paddington Foodbank</PhotoCredit>
								</div>
							</div>
							<Spacer desktop horizontalFill />
							<div className="row margin-mobile-top">
								<div className="col" ref={quote0}>
									<Quote author="Asli Ucyigit" active={quotes[0]}>
										I am a strong believer in <br/>
										equal opportunities and do <br/>
										what I can as an individual to <br/>
										promote that
									</Quote>
								</div>
								<Spacer desktop />
								<div style={imageStyles.image3} ref={image3}>
									<Image src="/images/asli/003.jpg" alt="Asli Ucyigit" paddedTop paddedBottom/>
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade3}>
							<TextBlock>
								<h2>Tell us about the volunteering work you do within Equinor UK</h2>
								<p>We have on-going volunteering opportunities across our UK offices, where I have led our efforts in London for several years with a focus on “Supporting Local Communities” around the Paddington area. Several of our volunteering opportunities are on a more permanent basis such as running Maths and Reading clubs at local schools, and others are ad-hoc and on-demand such as volunteering at the North Paddington foodbank, running IT sessions at Open Age, events at community centres such as Christmas parties, other school based events like painting over the summer and working with companies such as the Skills Builder Partnership exposing school children to the work environment.</p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image4}>
							<img src="/images/asli/004.jpg" alt="Asli Ucyigit" ref={image4}/>
							<img src="/images/asli/005.jpg" alt="Asli Ucyigit" ref={image5}/>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade4}>
							<TextBlock>
								<h2>Tell us about how Equinor encourages volunteering?</h2>
								<p>Equinor encourages employees to volunteer in the local community by allowing structured and varied opportunities to be put forward by myself, and allocating employees with 2 working days per year to engage in volunteering events.</p>
								<h2>What benefits does volunteering bring to you and your team mates?</h2>
								<p>Volunteering allows myself and colleagues time out of the working environment to support the local community and at the same time to re-connect with and meet new colleagues in a relaxed environment. It is a fantastic way to encourage internal and even external networking as we sometimes have joint events with other companies located in the Paddington area.</p>
							</TextBlock>
							<div className="asli-image4 desktop">
								<PhotoCredit position="Left">Operating since 2014 with 40-60 active volunteers</PhotoCredit>
							</div>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image6} ref={image6}>
							<Image src="/images/asli/006.jpg" alt="Asli Ucyigit" paddedTop paddedBottom/>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade5}>
							<div className="row">
								<TextBlock>
									<h2>How important especially at this time is volunteering in the community?</h2>
									<p>IVolunteering provides a support network for a lot of charities and organisations. During the current pandemic, this has been even more important. </p>
									<p>Following government guidelines and ensuring employee wellbeing has, however, meant many of our events this year were cancelled. Instead, we have focused on remote volunteering opportunities and providing monetary donations.</p>
								</TextBlock>
							</div>
						
							<div className="row" ref={quote1}>
								<div className="asli-quote1 margin-mobile-top">
									<Quote author="Asli Ucyigit" active={quotes[1]}>
										Volunteering provides a <br/>
										support network for a lot of <br/>
										charities and organisations
									</Quote>
								</div>
							</div>
						</div>

						<Spacer desktop />

						<div className="col" style={ imageStyles.image7 ? {width: imageStyles.image7.width} : null}>
							<div className="row asli7" style={imageStyles.image7} ref={image7}>
								<Image src="/images/asli/007.jpg" alt="Asli Ucyigit" paddedTop />
							</div>
							<div className="row" ref={image8}>
								<div style={{flex: 1}}></div>
								<div className="asli8">
									<img style={imageStyles.image8} src="/images/asli/008.jpg" alt="Asli Ucyigit" />
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade6}>
							<TextBlock>
								<h2>What is your proudest moment to date?</h2>
								<p>There is no one event that stands out but more the increased momentum and interest  in volunteering across the company that has happened over the last few years, and that this was reflected by the organisation allocating work days specifically for volunteering.</p>
								<div className="asli-extraspace"></div>
								<h2>Do you think Covid 19 has bought out the community spirit in people?</h2>
								<p>Of course when communities come together, so much more can be achieved. We have certainly seen this during the pandemic.</p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image9} ref={image9}>
							<div className="col" style={imageStyles.image9}>
								<Image src="/images/asli/009.jpg" alt="Asli Ucyigit"/>
							</div>
							
							<div className="col col-70 margin-mobile-top" ref={fade7}>
								<TextBlock>
									<h2>Do you consider yourself a hero for helping out?</h2>
									<p>There is nothing heroic about this, if anything it is selfish as I enjoy doing it! As well as helping others, there is a lot to gain as a volunteer; having fun, meeting new people, creating a sense of community and most importantly, providing a sense of reality which people should never forget, no matter what their situation.</p>
								</TextBlock>
							</div>
						</div>

						<Spacer />

						<div className="col" ref={quote2}>
							<Quote author="Asli Ucyigit" active={quotes[2]}>
								There is a lot to gain as a <br/>
								volunteer; having fun, <br/>
								meeting new people, creating <br/>
								a sense of community and <br/>
								most importantly, providing <br/>
								a sense of reality
							</Quote>
						</div>

						<Spacer desktop />

						<div className="col">
							<div className="row" ref={image10}>
								<div style={imageStyles.image10}>
									<Image src="/images/asli/010.jpg" alt="Asli Ucyigit" paddedTop paddedBottom/>
								</div>
								
							</div>

							<div className="row flex-mobile" ref={image11}>
								<div className="asli-photo11">
									<PhotoCredit position="Right">Asli and her fellow volunteers at North Paddington Foodbank</PhotoCredit>
								</div>
								<div style={imageStyles.image11} className="asli11">
									<Image src="/images/asli/011.jpg" alt="Asli Ucyigit"/>
								</div>
							</div>
						</div>

						<span className="mobile">
							<Spacer />
						</span>

						<div className="col" style={imageStyles.image12} ref={image12}>
							<Image src="/images/asli/012.jpg" alt="Asli Ucyigit" />
						</div>

						<span ref={thankRef}>
							<ThankYou author="Asli" active={thanks} />
						</span>

					</div>


					{/* Content */}


				<div className="progress">
					<div className="progress-bar" ref={progressBar}></div>
				</div>
			</div>
		</section>



		
	)
}


export default Volunteer

