import React from "react"
import styles from "./spacer.module.scss"

const Spacer = ({horizontalFill, desktop}) => {

	let classes = `${styles.spacer}`;
	if(horizontalFill) classes += ` ${styles.hfill}`;
	if(desktop) classes += ` desktop`;
  	
	return (
		<div className={classes}></div>
	)
}
export default Spacer

