import React, { useEffect, useLayoutEffect, useRef } from "react"
import styles from "./thankyou.module.scss"
import { gsap, Power3, Power1 } from 'gsap'
import SplitText from "gsap/SplitText"
import {useWindowWidth} from '@react-hook/window-size'
import { window } from 'browser-monads';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(SplitText, ScrollToPlugin);

const ThankYou = ({author, offset, active}) => {

	const timeline = useRef();
	const authorRef = useRef();
	const hr = useRef();
	const circle = useRef();
	const width = useWindowWidth()

	const style = offset ? {transform: `translateX(${offset}px)`} : null;

	const content = `Thank you ${author}`;

	useEffect(() => {
		if(timeline.current) return;
		if(width < 1100) return;

		if(active) {
			const split = new SplitText(authorRef.current, {type:"words, chars"});

			gsap.set(authorRef.current, {autoAlpha: 1});
			timeline.current = gsap.from(split.chars, {
				duration: 0.95,
				// yPercent: 10,
				alpha: 0,
				ease: Power3.easeOut,
				stagger: 0.075
			});

			gsap.fromTo(hr.current, {
				width: '0'
			}, {
				duration: 0.75,
				width: '100%',
				ease: Power1.easeInOut,
				delay: 0.45
			});

			gsap.to(circle.current, 1, {
				ease: Power3.easeOut,
				delay: 0.65,
				alpha: 1
			});
			
		}
		
	}, [active, width])

	useLayoutEffect(() => {
		if(width < 1100) return;

		// gsap.set(authorRef.current, {alpha: 0});
		gsap.set(hr.current, {width: '0%'});
		gsap.set(circle.current, {alpha: 0});

	}, [width])

	const onScrollTop = () => {
		gsap.to(window, {duration: 1.50, scrollTo: 0});
	}
  	
	return (
		<div className={styles.thankyou}>
			<div className={styles.tcontent} style={style}>
				<div className={styles.ttext} ref={authorRef}>{content}</div>
				<hr className={styles.thr} ref={hr}/>
				<img className={styles.ticon} ref={circle} src="/images/hexagon.svg" alt="" />
			</div>
			<div className={styles.thankyoutop} onClick={onScrollTop} onKeyPress={()=>{}} aria-hidden="true" role="menuitem">
				<span>TOP</span>
				<img className={styles.thankyousvg} src="/images/arrow-scroll.svg" alt="Scroll top" />
			</div>
		</div>
	)
}


export default ThankYou

