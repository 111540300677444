import React, { useEffect, useRef, useState } from "react"
import styles from "./menu.module.scss"
import {gsap, Power1, Power3} from 'gsap';
import {useWindowWidth} from '@react-hook/window-size'

const Menu = ({onGoto, onClose}) => {
	
	const bg = useRef();
	const about = useRef();
	const portraits = useRef();

	const geologist = useRef();
	const apprentice = useRef();
	const inventor = useRef();
	const volunteer = useRef();

	const width = useWindowWidth()
	const mobile = width < 1100;

	const [hover, setHover] = useState(-1);

	useEffect(() => {

		if(width >= 1100) {

			const autoAlpha = 0.999;

			gsap.to(bg.current, 0.50, {autoAlpha , ease:Power3.easeOut});

			gsap.to(about.current, 1, {autoAlpha , ease:Power1.easeOut});
			gsap.fromTo(portraits.current, 
				{alpha:0, x: 30},
				{autoAlpha, x:70, duration: 1.25, ease:Power3.easeOut}
			);

			gsap.to(geologist.current, 	1.75, {autoAlpha, ease:Power3.easeOut, delay: 0.40});
			gsap.to(apprentice.current, 1.75, {autoAlpha, ease:Power3.easeOut, delay: 0.65});
			gsap.to(inventor.current, 	1.75, {autoAlpha, ease:Power3.easeOut, delay: 0.85});
			gsap.to(volunteer.current, 	1.75, {autoAlpha, ease:Power3.easeOut, delay: 0.99});
		}

	}, [width])


	const onMenuClick = (index) => {
		if(mobile) {
			const to = index === hover ? -1 : index;
			setHover(to);
			return;
		}
		
		onGoto(index);
	}


	return (
		<div className={styles.mmenu}>
			<div className={styles.mbg} ref={bg}></div>
			<div className={styles.mband} onClick={onClose} onKeyPress={()=>{}} aria-hidden="true" role="menuitem">
				<div className={styles.mbandclose}>
					<img className={styles.mbandicon} src="/images/menu-x.svg" alt=""/>
				</div>
				<div className={styles.mbandlabel}>About the Energy Portraits</div>
			</div>

			<div className={styles.mtitle}>
				<div className={styles.mportraits} ref={portraits}>the portraits</div>
				<div className={styles.mabout} ref={about}>about</div>
			</div>

			<div className={styles.mlist}>
				<div className={styles.mitem} ref={geologist}>
					<div className={styles.mitemtitle}
						onClick={()=>{onMenuClick(0)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						role="menuitem"
					>
						<span className={styles.mindex}>01</span>
						<div className={styles.mlabel}>the geologist</div>
					</div>
					{(!mobile || hover === 0) && <div className={styles.msidehover}>
						<img className={styles.mhoverimage} src="/images/braces.svg" alt="" />
						<p
						onClick={()=>{onGoto(0)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						className={styles.mhovertext}>Hannah Mary, Equinor’s Area Development Manager for the Baltic Sea is passionate about energy and its effects on progress for society.  Transitioning from oil and gas to renewables, Hannah Mary actively uses her geologist background and skillset in her renewables work to creatively solve problems.</p>
					</div>}
				</div>

				<div className={styles.mitem} ref={apprentice}>
					<div className={styles.mitemtitle}
						onClick={()=>{onMenuClick(1)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						role="menuitem"
					>
						<span className={styles.mindex}>02</span>
						<div className={styles.mlabel}>the apprentice</div>
					</div>
					{(!mobile || hover === 1) && <div className={styles.msidehover}>
						<img className={styles.mhoverimage} src="/images/braces.svg" alt="" />
						<p
						onClick={()=>{onGoto(1)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						className={styles.mhovertext}>Mustafa started his energy journey as an apprentice and now works as a trainee engineer helping to build the world’s largest offshore windfarm at Dogger Bank, UK. Mustafa is very proud to be a part of such an important project that has the potential to benefit the whole world.</p>
					</div>}
				</div>

				<div className={styles.mitem} ref={inventor}>
					<div className={styles.mitemtitle}
						onClick={()=>{onMenuClick(2)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						role="menuitem"
					>
						<span className={styles.mindex}>03</span>
						<div className={styles.mlabel}>the inventor</div>
					</div>
					{(!mobile || hover === 2) && <div className={styles.msidehover}>
						<img className={styles.mhoverimage} src="/images/braces.svg" alt="" />
						<p
						onClick={()=>{onGoto(2)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						className={styles.mhovertext}>Meet Millie, 12 year old imagineer and creator of the solar powered pollution sucking robot pigeon. Millie won Equinor’s 2018 Young Imagineers competition for her inventive design concept. Millie represents a new generation of girls who want to use their passion for science and technology to create lasting change for our planet.</p>
					</div>}
				</div>

				<div className={styles.mitem} ref={volunteer}>
					<div className={styles.mitemtitle}
						onClick={()=>{onMenuClick(3)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						role="menuitem"
					>
						<span className={styles.mindex}>04</span>
						<div className={styles.mlabel}>the volunteer</div>
					</div>
					{(!mobile || hover === 3) && <div className={styles.msidehover}>
						<img className={styles.mhoverimage} src="/images/braces.svg" alt="" />
						<p
						onClick={()=>{onGoto(3)}}
						onKeyPress={()=>{}}
						aria-hidden="true"
						className={styles.mhovertext}>Hannah Mary, Equinor’s Area Development Manager for the Baltic Sea is passionate about energy and its effects on progress for society.  Transitioning from oil and gas to renewables, Hannah Mary actively uses her geologist background and skillset in her renewables work to creatively solve problems.</p>
					</div>}
				</div>

				
			</div>
		</div>
	)
}


export default React.memo(Menu)

