import { window } from 'browser-monads';

export const getSizeFor = (originalWidth, originalHeight, pageRatio) => {

	const wh = window && window.innerHeight ? window.innerHeight : 800;
	const minH = Math.min(wh, 800);
	
	const height = Math.round(minH * pageRatio);
	const ratio = height / originalHeight;
	const width = Math.round(originalWidth * ratio);

	return {width, height};
	
}