import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import styles from "./menu-band.module.scss"
import {gsap, Power0, Power3} from 'gsap';
import {useWindowWidth} from '@react-hook/window-size'
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { window } from 'browser-monads';
gsap.registerPlugin(ScrollToPlugin);


export const FADE_DURATION = 0.300;
export const FADE_EASE = Power0.easeInOut;
export const FADE_DELAY = "+=0.50";

const MenuBand = ({x, contentX, id, active, title, image, onClick, children, first}) => {

	const ref = useRef();
	const band = useRef();
	const content = useRef();
	const timeline = useRef();
	const bandleft = useRef();
	const bandright = useRef();
	const timelineContent = useRef();

	const aniTime = useRef(0);
	const width = useWindowWidth();

	const [show, setShow] = useState(width >= 1100);
	const mobile = width < 1100;

	// const onBandClick = () => {
	// 	if(mobile) {
	// 		setShow(show => !show);
	// 	} else {
	// 		onClick();
	// 	}
	// }

	useLayoutEffect(() => {
		if(mobile) {
			setShow(active);

			if(active){
				setTimeout(() => {
					gsap.to(window, {duration: 0.70, scrollTo: {y:ref.current, offsetY: 45}});
				}, 10);
			}
		}
	}, [active, mobile, ref])

	// useLayoutEffect(() => {
	// 	if(!mobile) return;

	// 	if(show || active) {
	// 		setTimeout(() => {
	// 			gsap.to(window, {duration: 0.70, scrollTo: {y:ref.current, offsetY: 30}});
	// 		}, 10);
	// 	}
	// }, [show, active, mobile])


	// DESKTOP ONLY
	useEffect(() => {
		if(!content.current) return;

		if(width < 1100) {
			return;
		}

		const posX = active ? 0 : -contentX;
		timelineContent.current = gsap.to(content.current, 2.15, {x: posX, ease: Power3.easeOut, force3D: false, transformStyle:"preserve-3d", transformPerspective:1000});
		// console.log(title, active, posX);

		const alpha = active ? 0 : 0.999;
		const delay = active ? 0 : 0.70;
		const time = active ? 0.50 : 0.50;
		const ease = active ? Power3.easeOut : Power0.easeOut;
		timeline.current = gsap.to(band.current, time, {autoAlpha: alpha, ease, delay, force3D: false, transformStyle:"preserve-3d", transformPerspective:1000});
		
		// gsap.to(bandleft.current, 0.25, {alpha, ease: Power3.easeOut});
		// gsap.to(bandright.current, 0.25, {alpha, ease: Power3.easeOut});

	}, [active, contentX, width])

	useEffect(() => {
		
		if(width < 1100) {
			return;
		}
		timeline.current = gsap.to(ref.current, aniTime.current, {x, ease: Power3.easeOut, force3D: false, transformStyle:"preserve-3d", transformPerspective:1000});
		if(x > 0) aniTime.current = 1.95;
	}, [x, width])

	return (
		<div className={styles.mwrapper} ref={ref}>
			<div className={styles.mband} onClick={()=>{onClick()}} onKeyPress={()=>{}} aria-hidden="true" role="menuitem" ref={band}>
				<div className={styles.mibandleft} ref={bandleft}></div>
				<div className={styles.mibandright} ref={bandright}></div>
				<div className={styles.mgradient}></div>
				<div className={styles.mtitle}>{title}</div>
			</div>
			
			{show &&
			<div className={styles.mcontent} ref={content}>
				{children}
			</div>}
		</div>
	)
}

export default MenuBand;

