import React, { forwardRef } from "react"
import styles from "./image.module.scss"


const Image = forwardRef(({src, alt = "", padded, paddedTop, paddedBottom}, ref) => {

	let classes = `${styles.iwrapper}`;
	if(padded) classes += ` ${styles.ipadded}`;
	if(paddedTop) classes += ` ${styles.itop}`;
	if(paddedBottom) classes += ` ${styles.ibottom}`;
	
	return (
		<div className={classes} ref={ref}>
			<img className={styles.iimage} src={src} alt={alt} />
		</div>
	)
})


export default Image

