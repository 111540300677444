import React from "react"
import styles from "./text-block.module.scss"

const TextBlock = ({children, noMargin}) => {

	let classes = `${styles.text}`;
	if(noMargin) classes += ` ${styles.nomargin}`;
  	
	return (
		<div className={classes}>
			{children}
		</div>
	)
}


export default TextBlock

