import React, { useRef, useEffect } from "react"
import styles from "./menu-about.module.scss"
import {gsap, Power3} from 'gsap';

const MenuAbout = ({onClick}) => {

	const menu = useRef();

	useEffect(() => {
		gsap.to(menu.current, 1.45, {x: 0, ease:Power3.easeInOut, delay: 0.75});
	} ,[])
  	
	return (
		<div className={styles.maband}
			onClick={onClick}
			onKeyPress={()=>{}}
			aria-hidden="true" 
			role="menuitem"
			ref={menu}
		>
			<div className={styles.maicon}></div>
			<div className={styles.malabel}>About the Energy Portraits</div>
		</div>
	)
}


export default MenuAbout

