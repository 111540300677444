import React from "react"
import PropTypes from "prop-types"
import styles from "./photo-credit.module.scss"

const PhotoCredit = ({position, light, children}) => {

	let classes = `${styles.photocredit}`;
	if(light) classes += ` ${styles.photolight}`;

	let hr = `${styles.photodiv}`;
	if(light) hr += ` ${styles.photodivlight}`;

	return (
		<div className={classes}>
			{position && <div className={styles.phototext}><strong>{position}</strong></div>}
			{position && <div className={hr} />}
			<div className={styles.phototext}>{children}</div>
		</div>
	)
}

PhotoCredit.propTypes = {
	children: PropTypes.node.isRequired,
	position: PropTypes.string
}

export default PhotoCredit

