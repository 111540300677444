import React, {useEffect, useLayoutEffect, useRef} from "react"
import styles from "./quote.module.scss"
import { gsap, Power3, Power1 } from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import {useWindowWidth} from '@react-hook/window-size'
gsap.registerPlugin(ScrollTrigger, SplitText);

const Quote = ({children, author, active, minWidth}) => {

	const timeline = useRef();
	const text = useRef();
	const bar = useRef();
	const authorName = useRef();
	const quotes = useRef();
	const sign = useRef();
	const width = useWindowWidth()

	useEffect(() => {
		if(timeline.current) return;
		if(width < 1100) return;

		if(active) {
			const split = new SplitText(text.current, {type:"words, lines"});

			timeline.current = gsap.from(split.lines, {
				duration: 0.95,
				yPercent: 20,
				alpha: 0,
				ease: Power3.easeOut,
				stagger: 0.095
			});

			gsap.fromTo(quotes.current, {
				x: -50,
				alpha: 0
			}, {
				x: 0,
				alpha: 1,
				duration: 1.15,
				ease: Power3.easeOut,
				delay: 0.35
			});

			gsap.fromTo(sign.current, {
				x: 50,
				alpha: 0,
			}, {
				duration: 1.25,
				ease: Power3.easeOut,
				delay: 0.5,
				x: 0,
				alpha: 1
			});
			
			gsap.fromTo(bar.current, {
				width: '0'
			}, {
				duration: 0.75,
				width: '100%',
				ease: Power1.easeInOut,
				delay: 0.45
			});

			gsap.fromTo(authorName.current, {
				alpha: 0,
				x: -50,
			}, {
				duration: 0.95,
				ease: Power3.easeOut,
				delay: 0.65,
				alpha: 1,
				x: 0
			});
		}
		
	}, [active, width])

	useLayoutEffect(() => {
		if(width < 1100) return;

		gsap.set(text.current, {alpha: 0});
		gsap.set(sign.current, {alpha: 0});
		gsap.set(quotes.current, {alpha: 0});

	}, [width])
  	
	return (
		<div className={styles.quote}>
			{/* <div className={styles.qghost}>{children}</div> */}
			<img className={styles.qimage} src="/images/quote.svg" alt={author || ""} ref={quotes}/>
			<span className={styles.qtext} ref={text}>{children}</span>
			<div className={styles.qauthor} ref={sign}>
				<div className={styles.qauthorBar}>
					<div className={styles.qauthorBarinner} ref={bar}></div>
				</div>
				<div className={styles.qauthorName} ref={authorName}>{author}</div>
			</div>
		</div>
	)
}


export default Quote

