import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react"
import JobTitle from "../job-title/job-title";
import PersonName from "../person-name/person-name";
import PhotoCredit from "../photo-credit/photo-credit";
import Quote from "../quote/quote";
import ThankYou from "../thankyou/thankyou";
import Image from "../image/image";
import { getSizeFor } from "../../globals/ImageSize";
import TextBlock from "../text-block/text-block";
import {gsap, Power1} from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './inventor.scss';
import ArrowScroll from "../arrow-scroll/arrow-scroll";
import Spacer from "../spacer/spacer";
import {useWindowWidth} from '@react-hook/window-size'
import useSize from '../../hooks/useSize';
gsap.registerPlugin(ScrollTrigger);

const Inventor = ({active}) => {

	/**
	 * 
	 * This should be a HOC as base, wrapper, progress are the same across all 4 sections
	 * as well as all refs, unfortunately there is no time to develop and debug it :()
	 */

	const base = useRef();
	const wrapper = useRef(null);
	const progressBar = useRef();
	const timeline = useRef();
	const sectionScrollBar = useRef();
	const thankRef = useRef();
	const width = useWindowWidth()

	const [imageStyles, setStyles] = useState({});
	useEffect(() => {
		let styles = {}

		if(width >= 1100) {
			styles = {
				image1: {width:getSizeFor(1194, 1550, 1).width},
				image2: {width:getSizeFor(1422, 926, 0.65).width},
				image2A: { width: getSizeFor(1422, 926, 0.65).width, height: getSizeFor(1422, 926, 0.65).height },
				image3: { width: getSizeFor(926, 620, 0.35).width, height: getSizeFor(926, 620, 0.35).height },
				image4: {width:getSizeFor(1100, 981, 0.70).width},
				image5: {width:getSizeFor(2134, 1544, 1).width},
				image6: getSizeFor(1472, 982, 0.60),
				image7: {width:getSizeFor(1100, 923, 0.55).width},
				image8: {width:getSizeFor(2134, 1394, 1).width-80},
				image9: {width:getSizeFor(1065, 697, 0.5).width-20},
				image10: {width:getSizeFor(1066, 697, 0.5).width-20},
				image11: {width:getSizeFor(2131, 1576, 1).width},
			}
		}

		setStyles(styles);
	}, [width])

	const size = useSize(wrapper);
	useLayoutEffect(() => {
		if(!size || !timeline.current) return;
		ScrollTrigger.refresh(true);
	}, [size])


	const fade1 = useRef();
	const fade2 = useRef();
	const fade3 = useRef();
	const fade4 = useRef();
	const fade5 = useRef();
	const fade6 = useRef();
	const fade7 = useRef();
	const fade8 = useRef();
	const fade9 = useRef();
	const fade10 = useRef();

	const image2 = useRef();
	const image4 = useRef();
	const image5 = useRef();
	const image6 = useRef();
	const image7 = useRef();
	const image8 = useRef();
	const image9 = useRef();
	const image10 = useRef();
	const image11 = useRef();


	const quote0 = useRef();
	const quote1 = useRef();
	const quote2 = useRef();
	const [quotes, setQuotes] = useState([])
	const [thanks, setThanks] = useState(false)

	const onActiveQuote = useCallback((index) => {
		const q = [...quotes];
		q[index] = true;
		setQuotes(q);
	}, [quotes])


	useEffect(() => {
		if(!wrapper.current) return;

		if(timeline.current && !active && size && width){
			
			const x = sectionScrollBar.current.scrollLeft;
			const w = size.width - width;
			const p = x / w;
			const t = (5000 * p) + 500;
			sectionScrollBar.current.scrollTo(0, 0, t);
			
			setQuotes([]);
		}
	}, [active, wrapper, size, width]);

	useLayoutEffect(() => {
		if(!imageStyles.image1 || timeline.current) {
			return;
		}

		sectionScrollBar.current = Scrollbar.init(base.current,
			{	damping: 0.15,
				// delegateTo: document
			});
		sectionScrollBar.current.setPosition(0, 0);
		sectionScrollBar.current.track.yAxis.element.remove();

		ScrollTrigger.scrollerProxy(base.current, {
			scrollLeft(value) {
				// console.log(`🚀 ~ file: base-section.js ~ line 58 ~ scrollLeft ~ value`, sectionScrollBar.current.scrollLeft);
				
                if (arguments.length) {
					sectionScrollBar.current.scrollLeft = value;
				}
				return sectionScrollBar.current.scrollLeft;
			}
		});

		timeline.current = gsap.timeline({
			scrollTrigger: {
				scroller: base.current,
				trigger: wrapper.current,
				horizontal: true,
				scrub: 0,
				// pin: true,
				// markers: true,
				start: "0",
				end: "right right",
				onUpdate: ({progress, direction, isActive}) => {
					// console.log(progress, direction, isActive)
				}
			}
		}).to(progressBar.current, {scaleX: 1, ease: "none"}, 0);




		const fadeins = [fade1.current, fade2.current, fade3.current, fade4.current, fade5.current, fade6.current, fade7.current, fade8.current, fade9.current, fade10.current];
		fadeins.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 75%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				ease: Power1.easeOut
			});
		});
		
		const slides = [image4.current, image5.current, image6.current, image7.current, image8.current, image10.current, image11.current];
		slides.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				x: 100,
				ease: Power1.easeOut
			});
		});

		const slideTop = [image2.current, image9.current];
		slideTop.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				y: -30,
				ease: Power1.easeOut
			});
		});


		
		const quotesArray = [quote0.current, quote1.current, quote2.current];
		quotesArray.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 60%",
					// markers: true,
					horizontal: true,
					// scrub: 1
					onEnter: ({progress, direction, isActive}) => {
						onActiveQuote(index);
					}
				},
				duration: 0,
			});
		});

		gsap.from(thankRef.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: thankRef.current,
				toggleActions: "restart none none reverse",
				start: "left 25%",
				horizontal: true,
				onEnter: ({progress, direction, isActive}) => {
					setThanks(true);
				}
			},
			duration: 0,
		});


		sectionScrollBar.current.addListener(ScrollTrigger.update);


		return () => {
			setQuotes([])
			timeline.current && timeline.current.kill();
		}

	}, [onActiveQuote, imageStyles])




	
	return (

		<section className="base" ref={base}>
			<div className="wrapper" ref={wrapper}>


					{/* Content */}
					

					<div className="inventor">

						<div className="col" style={imageStyles.image1}>
							<Image src="/images/millie/001.jpg" alt="Millie Iversen-Gray"/>
							<ArrowScroll x={-40} />
						</div>
						
						<Spacer />
						
						<div className="col col-50" ref={fade1}>
							<PersonName>Millie Iversen-Gray</PersonName>
							<hr />
							<JobTitle>INVENTOR OF THE SOLAR<br/>POWERED POLLUTION SUCKING<br/>ROBOT PIGEON</JobTitle>
							<p className="extra-margin-bottom">Meet Millie, 12 year old imagineer and creator of the solar powered pollution sucking robot pigeon. Millie won Equinor’s 2018 Young Imagineers competition for her inventive design concept. Millie represents a new generation of girls who want to use their passion for science and technology to create lasting change for our planet.</p>
							<PhotoCredit>Photography by Sean Pollock</PhotoCredit>							
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image2}>
							<div className="row millie2" style={imageStyles.image2A} ref={image2}>
								<Image src="/images/millie/002.jpg" alt="Millie Iversen-Gray" />
							</div>
							<div className="row flex-mobile" ref={fade2}>
								<div className="padded-mobile-sides">
									<PhotoCredit position="Above & Right">Millie’s pollution eating pigeon</PhotoCredit>
								</div>
								<div className="millie3">
									<img style={imageStyles.image3}  src="/images/millie/003.jpg" alt="Millie Iversen-Gray" />
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade3}>
							<div className="milliefade3">
								<TextBlock>
									<h2>How did you come up with the idea of the solar powered pollution sucking robot pigeon?</h2>
									<p>Reading the paper and seeing the competition for Young Imagineers, thinking about pollution and sketching out ideas on a piece of paper in my bed. </p>
									<p>And then talking to my family about it who encouraged me to think some more and try to draw the concept.</p>
								</TextBlock>
							</div>
						</div>

						<Spacer />

						<div className="col millie4" style={imageStyles.image4} ref={image4}>
							<div style={imageStyles.image4}>
								<Image paddedlarge src="/images/millie/004.jpg" alt="Millie Iversen-Gray" />
							</div>
							
							<div className="padded-mobile-right millie4photo">
								<PhotoCredit position="Above">Millie’s design sketches</PhotoCredit>
							</div>
						</div>

						
						<Spacer />

						<div className="col col-70" ref={fade4}>
							<TextBlock>
								<h2>Can you talk us through the concept?</h2>
								<p>The idea is the Solar Powered Pollution Sucking Robot Pigeon which is my invention to make toxic air good again. Robot Pigeons will fly around the world’s biggest cities and suck all the polluted air in through their beaks through a filter in their tummy, where pipes and filters will turn the polluted air clean again and spray it out through the back feather for us all to breathe in.</p>
								<p>The pigeons will be powered by solar panels on their wings and tail, and the idea is designed as a pigeon so that other birds won’t get startled by it. I want to make sure it doesn’t affect wildlife badly as it is designed to be an idea that can benefit us all. </p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image5} ref={image5}>
							<Image src="/images/millie/005.jpg" alt="Millie Iversen-Gray"/>
							<div className="millie-photo5 desktop">
								<PhotoCredit light position="Right">Millie at home</PhotoCredit>
							</div>
							<div className="millie-quote5" ref={quote0}>
								<Quote author="Millie Iversen-Gray" active={quotes[0]}>
									There is a plan B, if we learn, <br/>listen and do!
								</Quote>
							</div>
						</div>

						<Spacer desktop />

						<div className="col">
							<div className="row">
								<div className="col col-70" ref={fade5}>
									<TextBlock>
										<h2>What was your lightbulb moment?</h2>
										<p>I was lying in bed and looking out of the window to the pigeons in the tree outside our house. I had just been reading about how much pollution there is in London and we also kept hearing about it at school and on the news. And I got thinking that as there are so many pigeons in London, and in all big cities, and as there is so much pollution, could I find a way to combine the two to make everyone in the world breathe cleaner air? We have the solar and robotic technology to do it and make it happen, we just need to combine it with design differently.</p>
									</TextBlock>
								</div>
								<div className="col col-50"></div>
							</div>

							<div className="row">
								<div className="col col-50"></div>
								<div className="col col-70" ref={fade6}>
									<TextBlock noMargin>
										<h2>How do you think the world should solve the climate crisis?</h2>
										<p>The people making decisions in the world should listen to scientists, not to stupid politicians who are denying that global warming is happening. They need to work with young people too who have unusual ideas and combine creative thinking with science. </p>
										<p>And from that find ways to solve things, not just make people worried and give up hope by the constant thinking that there is no plan B. There is a plan B, if we learn, listen and do!</p>
									</TextBlock>
								</div>
							</div>
						</div>

						<Spacer />


						<div className="col" style={imageStyles.image6?{width:imageStyles.image6.width}:null} ref={image6}>
							<div className="row millie6" style={imageStyles.image6}>
								<Image src="/images/millie/006.jpg" alt="Millie Iversen-Gray" paddedTop/>
							</div>
							
							<div className="row">
								<div className="mobile flex-mobile">
									<div className="padded-mobile-sides">
										<PhotoCredit position="Right">Millie and her mum, Britt</PhotoCredit>
									</div>
									<div className="" style={imageStyles.image7} ref={image7}>
										<Image src="/images/millie/007.jpg" alt="Millie Iversen-Gray"/>
									</div>
								</div>
								<div className="mobile">
									<Spacer />
								</div>
							</div>
						</div>


						<div className="col col-millie6 desktop">
							<div className="millie6">
								<PhotoCredit position="Right">Millie and her mum, Britt</PhotoCredit>
							</div>
						</div>

						<div className="col col-70" style={imageStyles.image7}>
							<div className="col" style={imageStyles.image7} ref={fade7}>
								<TextBlock noMargin>
									<h2>What did you feel when you found out you had won the Young Imagineers competition?</h2>
									<p>Shocked, couldn’t believe it, and so so excited and happy too. It was the first time I’d won anything! And just such a brilliant experience.</p>
								</TextBlock>
							</div>
							
							<div className="col desktop" style={imageStyles.image7} ref={image7}>
								<Image src="/images/millie/007.jpg" alt="Millie Iversen-Gray"/>
							</div>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade8}>
							<TextBlock>
								<h2>Has winning the Young Imagineers competition made a difference to you? Do you want to keep inventing? What’s your next invention?</h2>
								<p>It’s made a huge difference in how confident I feel in myself, in my ideas and also in talking about them to different people. And it’s made me think of science differently too, and how creative I can be with that, which I love. I love when we do experiments in science at school, and  I can’t wait for the experiments to come back once Covid is under control.</p>
								<p>I do a lot of creative projects at school that I love, from beeswax wrapping paper to animation projects and ideas for how we could live on Mars. At home I do a lot of creative games with my family and friends, like homemade cardboard buildings or supporting my sister building a go-kart. I just like making things and being aware of what is going on in the world around us. And combining things in new ways.</p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image8} ref={image8}>
							<Image src="/images/millie/008.jpg" alt="Millie Iversen-Gray" paddedTop paddedBottom/>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade9}>
							<TextBlock>
								<h2>What are your hopes for the future of energy in the UK?</h2>
								<p>Obviously it needs to be eco friendly. We need to reduce how much CO2 and SO2 is emitted into the world. We need to put what’s good for the environment first and we also need to encourage people to be more considerate in how and how much they use energy every day.</p>
								<p>We need to have solar panels on most buildings, maybe even different kinds of solar panels that fit better with buildings. We need wind farms in barren areas - like they are in Denmark or like solar farms are in Morocco. We could also look to create energy from water as we have so much around us.</p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col millie-quote1" ref={quote1}>
							<Quote author="Millie Iversen-Gray" active={quotes[1]}>
								We need to reduce how <br/>
								much CO<span className="sup">2</span> is emitted into <br/>
								the world
							</Quote>
						</div>

						<div className="col">
							<div className="row">
								<div className="col" style={imageStyles.image9} ref={image9}>
									<Image src="/images/millie/009.jpg" alt="Millie Iversen-Gray"/>
								</div>
								<div className="row mobile flex-mobile">
									<div className="padded-mobile-sides">
										<PhotoCredit position="Right & Above">Millie at home</PhotoCredit>
									</div>

									<div className="col" style={imageStyles.image10} ref={image10}>
										<Image src="/images/millie/010.jpg" alt="Millie Iversen-Gray"/>
									</div>
								</div>
								<Spacer />
								<div className="col col-70" ref={fade10}>
									<TextBlock>
										<h2>What would be your dream job?</h2>
										<p>I don’t really know yet but I like the idea of being either an eco scientist warrior or working with creative arts, either way working with something that impacts on how we live and how we can all help make good things happen for our future. </p>
									</TextBlock>
								</div>
								<Spacer />
							</div>
							
							<Spacer horizontalFill />

							<div className="row desktop">
								<Spacer />
								<Spacer />
								<div className="millie-photo8">
									<PhotoCredit position="Right & Above">Millie at home</PhotoCredit>
								</div>

								<div className="col" style={imageStyles.image10} ref={image10}>
									<Image src="/images/millie/010.jpg" alt="Millie Iversen-Gray"/>
								</div>
							</div>
						</div>

						<div className="col" style={imageStyles.image11} ref={image11}>
							<Image src="/images/millie/011.jpg" alt="Millie Iversen-Gray"/>
							<div className="millie-quote11" ref={quote2}>
								<Quote author="Millie Iversen-Gray" active={quotes[2]}>
									I like the idea of being an eco <br/>
									scientist or working with <br/>
									creative arts
								</Quote>
							</div>
						</div>

						<span ref={thankRef}>
							<ThankYou author="Millie" active={thanks} />
						</span>

						
					</div>

					{/* Content */}


				<div className="progress">
					<div className="progress-bar" ref={progressBar}></div>
				</div>
			</div>
		</section>



		
	)
}


export default Inventor

