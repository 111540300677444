import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import Apprentice from "../components/apprentice/apprentice"
import Geologist from "../components/geologist/geologist"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import Inventor from "../components/inventor/inventor"
import MenuBand from "../components/menu-band/menu-band"
import Volunteer from "../components/volunteer/volunteer"
import {useWindowWidth} from '@react-hook/window-size'
import Menu from "../components/menu/menu"
import Intro from "../components/intro/intro"
import IntroArrow from "../components/intro-arrow/intro-arrow"
import MenuAbout from "../components/menu-about/menu-about"
import MenuMobile from "../components/menu-mobile/menu-mobile"
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/dist/plugins/overscroll';
import HorizontalScrollPlugin from '../globals/HorizontalScrollPlugin';
import { window } from 'browser-monads';

const BAND_SIZE = 65;

Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);

const IndexPage = () => {

	
	const width = useWindowWidth();
	const positions = useRef([]);

	const [showMenu, setShowMenu] = useState(false);
	const [loading, setLoading] = useState(true);
	const [active, setActive] = useState(-1);
	const [x0, setX0] = useState(0);
	const [x1, setX1] = useState(0);
	const [x2, setX2] = useState(0);
	const [x3, setX3] = useState(0);
	
	const goto = (index) => {
		if(index === active) index = -1;
		setActive(index);

		const calls = [setX0, setX1, setX2, setX3];
    	for (let i = 0; i < 4; i++) {
			if(i <= index) {
				calls[i]( positions.current[i].left );
			} else {
				calls[i]( positions.current[i].right );
			}
		}
	}
	
	const fromMenuGoto = (index) => {
		onToggleMenu();
		goto(index);
	}

	const onToggleMenu = () => {
		// setActive(-1);
		setShowMenu(showMenu => !showMenu);
	}
    

	useEffect(() => {
		if(!width) return;

		for (let i = 0; i < 4; i++) {
			const left = (BAND_SIZE * i);
			const right = width - BAND_SIZE - (BAND_SIZE * (4-i)) + 1; // window_width - left_band - right_bands
			
			positions.current[i] = {left, right};
		}

		setX0(positions.current[0].right);
		setX1(positions.current[1].right);
		setX2(positions.current[2].right);
		setX3(positions.current[3].right);

	}, [width]);

	useLayoutEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 30);

		// document.addEventListener('visibilitychange', function(e) {
		// 	console.log(document.hidden);
		// });

		// window.addEventListener('wheel', (event) => {
		// 	console.log(event)
		// });
	}, [])


	const getContentX = (value) => {
		const wh = window && window.innerHeight ? window.innerHeight : 800;
		const minH = Math.min(wh, 800);
		const pos = minH * value / 800; 
		return pos;
	}


	return (
		<Layout>
			<SEO title="Home" />

			{loading && <div className="loading"></div>}
			
			{!loading &&
			<div className="home-content">

				<MenuAbout onClick={onToggleMenu} />
				<Intro />
				<IntroArrow />

				<div className="home-content-bands">
					<MenuBand
						active={active===0}
						x={x0}
						first
						contentX={getContentX(860)}
						title="the geologist" 
						image="/images/mobile-geologist.jpg" 
						onClick={()=>{goto(0)}}
						>
							<Geologist active={active===0}/>
					</MenuBand>
					
					<MenuBand
						active={active===1}
						x={x1}
						contentX={getContentX(340)}
						title="the apprentice"
						image="/images/mobile-apprentice.jpg" 
						onClick={()=>{goto(1)}}
						>
							<Apprentice active={active===1}/>
					</MenuBand>
					
					<MenuBand
						active={active===2}
						x={x2}
						contentX={getContentX(212)}
						title="the inventor" 
						image="/images/mobile-inventor.jpg" 
						onClick={()=>{goto(2)}}
						>
							<Inventor active={active===2}/>
					</MenuBand>
					
					<MenuBand
						active={active===3}
						x={x3}
						contentX={getContentX(860)}
						title="the volunteer" 
						image="/images/mobile-volunteer.jpg"  
						onClick={()=>{goto(3)}}
						>
							<Volunteer active={active===3}/>
					</MenuBand>
				</div>

				<MenuMobile active={active} onClick={(index)=>{goto(index)}} />

				{showMenu && <Menu onGoto={fromMenuGoto} onClose={onToggleMenu} />}

			</div>}
			
		</Layout>
	)
}

export default IndexPage

