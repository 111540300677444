import React, { useEffect, useRef } from "react"
import styles from "./intro.module.scss"
import {gsap, Power1, Power3} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
gsap.registerPlugin(ScrollTrigger, SplitText);

const Intro = () => {

	const how = useRef();
	const local = useRef();
	const building = useRef();
	const text = useRef();

	useEffect(() => {
		const sLocal = new SplitText(local.current, {type:"words"});
		const sBuild = new SplitText(building.current, {type:"words"});

		gsap.to(how.current, 1.5, {alpha: 1, ease:Power1.easeOut, delay: 0.5});

		gsap.from(sLocal.words, {
			duration: 1.75,
			delay: 0.95,
			yPercent: 50,
			alpha: 0,
			ease: Power3.easeOut,
			stagger: 0.395
		});
		
		gsap.from(sBuild.words, {
			duration: 0.95,
			delay: 1.15,
			yPercent: 40,
			alpha: 0,
			ease: Power1.easeOut,
			stagger: 0.225
		});

		gsap.to(text.current, 1.5, {alpha: 1, ease:Power1.easeOut, delay: 1.75});

		
	}, []);
  	
	return (
		<div className={styles.intro}>
			<div className={styles.introcopy}>
				<div className={styles.introtitle}>
					<span className={styles.introlocal} ref={local}>local people</span>
					<span className={styles.introhow} ref={how}>How</span>
				</div>
				<div className={styles.introbuild} ref={building}>are building the energy revolution</div>
				<div className={styles.introdescr} ref={text}>
					<p>Our path to Net Zero requires action from us all. We need bold ideas and a collaborative mindset to solve the energy challenge. Read how four inspirational people are making an impact through their commitment and ingenuity.</p>
					
					<div className={styles.introdescrarrow}>
						<div className={styles.introdescrarrowcontent}>
							<span>Scroll down to explore</span>
							<img className={styles.introdescrsvg} src="/images/arrow-scroll.svg" alt="Scroll right" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default React.memo(Intro);

