import React from "react"
import PropTypes from "prop-types"
import styles from "./job-title.module.scss"

const JobTitle = ({children}) => {

	return (
		<p className={styles.title}>{children}</p>
	)
}

JobTitle.propTypes = {
	children: PropTypes.node.isRequired,
}

export default JobTitle

