import React, { useEffect, useRef } from "react"
import styles from "./intro-arrow.module.scss"
import {gsap, Power1} from 'gsap';

const IntroArrow = () => {

	const arrow = useRef();

	useEffect(() => {
		gsap.to(arrow.current, 1.25, {alpha: 1, x: 0, ease:Power1.easeOut, delay: 1.25});
	} ,[])
  	
	return (
		<div className={styles.introarrow} ref={arrow}>
			<span>Click on the bands to explore</span>
			<img className={styles.introsvg} src="/images/arrow-scroll.svg" alt="Scroll right" />
		</div>
	)
}


export default IntroArrow

