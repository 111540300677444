import React from "react"
import styles from "./arrow-scroll.module.scss"

const ArrowScroll = ({x, left}) => {

	let classes = `${styles.ararrow}`;
	if(left) classes += ` ${styles.arleft}`

	return (
		<div className={classes} style={{transform: `translateX(${x}px)`}}>
			<span>Scroll right</span>
			<img className={styles.arsvg} src="/images/arrow-scroll.svg" alt="Scroll right" />
		</div>
	)
}


export default ArrowScroll

