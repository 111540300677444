import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react"
import JobTitle from "../job-title/job-title";
import PersonName from "../person-name/person-name";
import PhotoCredit from "../photo-credit/photo-credit";
import Quote from "../quote/quote";
import ThankYou from "../thankyou/thankyou";
import Image from "../image/image";
import { getSizeFor } from "../../globals/ImageSize";
import TextBlock from "../text-block/text-block";
import {gsap, Power1} from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './apprentice.scss';
import ArrowScroll from "../arrow-scroll/arrow-scroll";
import Spacer from "../spacer/spacer";
import {useWindowWidth} from '@react-hook/window-size'
import useSize from '../../hooks/useSize';
gsap.registerPlugin(ScrollTrigger);

const Apprentice = ({active}) => {

	/**
	 * 
	 * This should be a HOC as base, wrapper, progress are the same across all 4 sections
	 * as well as all refs, unfortunately there is no time to develop and debug it :()
	 */

	const base = useRef();
	const wrapper = useRef(null);
	const progressBar = useRef();
	const timeline = useRef();
	const sectionScrollBar = useRef();
	const thankRef = useRef();
	const width = useWindowWidth()

	const [imageStyles, setStyles] = useState({});
	useEffect(() => {
		let styles = {}

		if(width >= 1100) {
			styles = {
				image1: {width:getSizeFor(1656, 1574, 1).width},
				image2: {width:getSizeFor(1458, 950, 0.65).width},
				image2A: { width: getSizeFor(1458, 950, 0.65).width, height: getSizeFor(1458, 950, 0.65).height },
				image3: { width: getSizeFor(928, 620, 0.35).width, height: getSizeFor(928, 620, 0.35).height },
				image4: {width:getSizeFor(1412, 982, 0.70).width},
				image5: {width:getSizeFor(2134, 1422, 1).width-80},
				image6: getSizeFor(1176, 785, 0.50),
				image7: getSizeFor(1176, 785, 0.50),
				image8: getSizeFor(1176, 785, 0.50),
				image9: getSizeFor(1096, 731, 0.50),
				image10: {width:getSizeFor(2604, 1594, 1).width},
			}
		}

		setStyles(styles);
	}, [width])


	const size = useSize(wrapper);
	useLayoutEffect(() => {
		if(!size || !timeline.current) return;
		ScrollTrigger.refresh(true);
	}, [size])


	const fade1 = useRef();
	const fade2 = useRef();
	const fade3 = useRef();
	const fade4 = useRef();
	const fade5 = useRef();
	const fade6 = useRef();
	const fade7 = useRef();
	const fade8 = useRef();
	const fade9 = useRef();

	const image2 = useRef();
	const image3 = useRef();
	const image4 = useRef();
	const image5 = useRef();
	const image6 = useRef();
	const image7 = useRef();
	const image8 = useRef();
	const image9 = useRef();
	const image10 = useRef();



	const quote0 = useRef();
	const quote1 = useRef();
	const [quotes, setQuotes] = useState([])
	const [thanks, setThanks] = useState(false)

	const onActiveQuote = useCallback((index) => {
		const q = [...quotes];
		q[index] = true;
		setQuotes(q);
	}, [quotes])


	useEffect(() => {
		if(!wrapper.current) return;

		if(timeline.current && !active && size && width){
			
			const x = sectionScrollBar.current.scrollLeft;
			const w = size.width - width;
			const p = x / w;
			const t = (5000 * p) + 500;
			sectionScrollBar.current.scrollTo(0, 0, t);
			
			setQuotes([]);
		}
	}, [active, wrapper, size, width]);

	useLayoutEffect(() => {
		if(!imageStyles.image1 || timeline.current) {
			return;
		}

		sectionScrollBar.current = Scrollbar.init(base.current,
			{	damping: 0.15,
				// delegateTo: document
			});
		sectionScrollBar.current.setPosition(0, 0);
		sectionScrollBar.current.track.yAxis.element.remove();

		ScrollTrigger.scrollerProxy(base.current, {
			scrollLeft(value) {
				// console.log(`🚀 ~ file: base-section.js ~ line 58 ~ scrollLeft ~ value`, sectionScrollBar.current.scrollLeft);
				
                if (arguments.length) {
					sectionScrollBar.current.scrollLeft = value;
				}
				return sectionScrollBar.current.scrollLeft;
			}
		});

		timeline.current = gsap.timeline({
			scrollTrigger: {
				scroller: base.current,
				trigger: wrapper.current,
				horizontal: true,
				scrub: 0,
				// pin: true,
				// markers: true,
				start: "0",
				end: "right right",
				onUpdate: ({progress, direction, isActive}) => {
					// console.log(progress, direction, isActive)
				}
			}
		}).to(progressBar.current, {scaleX: 1, ease: "none"}, 0);




		const fadeins = [fade1.current, fade2.current, fade3.current, fade4.current, fade5.current, fade6.current, fade7.current, fade8.current, fade9.current];
		fadeins.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: index === 1 ? "left 90%" : "left 75%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				ease: Power1.easeOut
			});
		});
		
		const slides = [image3.current, image4.current, image5.current, image6.current, image7.current, image8.current, image9.current, image10.current];
		slides.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				x: 100,
				ease: Power1.easeOut
			});
		});

		const slideTop = [image2.current];
		slideTop.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				y: -30,
				ease: Power1.easeOut
			});
		});


		

		const quotesArray = [quote0.current, quote1.current];
		quotesArray.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 60%",
					// markers: true,
					horizontal: true,
					// scrub: 1
					onEnter: ({progress, direction, isActive}) => {
						onActiveQuote(index);
					}
				},
				duration: 0,
			});
		});


		gsap.from(thankRef.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: thankRef.current,
				toggleActions: "restart none none reverse",
				start: "left 25%",
				horizontal: true,
				onEnter: ({progress, direction, isActive}) => {
					setThanks(true);
				}
			},
			duration: 0,
		});


		sectionScrollBar.current.addListener(ScrollTrigger.update);
		

		return () => {
			setQuotes([])
		}

	}, [onActiveQuote, imageStyles])




	
	return (

		<section className="base" ref={base}>
			<div className="wrapper" ref={wrapper}>
					
					


					{/* Content */}
					

					<div className="apprentice">
						
						<div className="col" style={imageStyles.image1} ref={fade1}>
							<Image src="/images/mustafa/001.jpg" alt="Mustafa Hamed"/>
							<ArrowScroll x={-150} />
						</div>

						<Spacer />

						<div className="col col-50" ref={fade2}>
							<PersonName>Mustafa Hamed</PersonName>
							<hr />
							<JobTitle>APPRENTICE ENGINEER AT DOGGER BANK WIND FARM</JobTitle>
							<p>Mustafa started his energy journey as an apprentice and now works as a trainee engineer helping to build the world’s largest offshore windfarm at Dogger Bank, UK. Mustafa is very proud to be a part of such an important project that has the potential to benefit the whole world.</p>
							<PhotoCredit>Photography by Sean Pollock</PhotoCredit>							
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image2}>
							<div className="row mustafa2" style={imageStyles.image2A} ref={image2}>
								<Image src="/images/mustafa/002.jpg" alt="Mustafa Hamed" />
							</div>
							<div className="row flex-mobile" ref={image3}>
								<div className="padded-mobile-sides">
									<PhotoCredit position="Above & Right">Mustafa on site at Dogger Bank. Onshore construction began in Jan 2020</PhotoCredit>
								</div>
								<div className="mustafa1">
									<img style={imageStyles.image3} src="/images/mustafa/003.jpg" alt="Mustafa Hamed" />
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col col-70" ref={fade3}>
							<TextBlock>
								<h2>Why did you take the apprentice route?</h2>
								<p>I originally studied mechanical engineering at Salford University for one year, but left because it didn’t appeal to me. I didn’t like that I was sat down for most of the day when I wanted to be actively gaining real-life experience on site.</p>
								<p>I also didn’t like the prospect of student debt.<br/>This led me to applying for an apprenticeship with Jones Bros as a plant operator as I saw it as a way into the industry and they were working on a project in my hometown, the Wigan A49 link road, so it was ideal. It was a fantastic chance to work on such a significant scheme early in my career. </p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" ref={image4}>
							<div className="mustafa-row4"></div>
							
							<div style={imageStyles.image4}>
								<Image paddedlarge src="/images/mustafa/004.jpg" alt="Mustafa Hamed" />
							</div>
							
							<div className="mustafa-row4 padded-mobile-left">
								<PhotoCredit position="Above">Onshore pipe installation at Dogger Bank</PhotoCredit>
							</div>
						</div>
						
						<Spacer />
						
						<div className="col col-70" ref={fade4}>
							<TextBlock noMargin>
								<h2>Tell us what your role here is at Dogger Bank?</h2>
								<p>I started as a plant operator, but my aim was always to become a civil engineer. Working on Dogger Bank was my chance to ask Jones Bros if I could work on the project as a trainee engineer and they agreed. A typical day involves logging onto the systems and checking what work needs to be done and what’s completed.</p>
								<p>On site, an example activity is using GPS to carry out levels and alignment checks for trenching operations, surveying of the installed electrical ducting and carrying final CCTV checks to ensure that no damage has occurred during backfilling operations and that the ducts are clear of any debris prior to the cable installation. </p>
								<p>It helps that I’m with experienced people who have been in my position, which really enhances my learning. </p>
							</TextBlock>
						</div>

						<Spacer />

						<div className="col" style={imageStyles.image5} ref={image5}>
							<Image src="/images/mustafa/005.jpg" alt="Mustafa Hamed" paddedTop paddedBottom/>
							<div className="col mustafa5 desktop" ref={fade5}>
								<PhotoCredit position="Right">Alignment checks for trenches</PhotoCredit>
							</div>
						</div>

						<div className="row mobile flex-mobile">
							<div className="col" style={imageStyles.image6} ref={image6}>
								<Image src="/images/mustafa/006.jpg" alt="Mustafa Hamed" paddedBottom/>
							</div>
							<div className="col mustafa6 padded-mobile-sides" ref={fade7}>
								<PhotoCredit position="Left">Installation of 30km of onshore cable</PhotoCredit>
							</div>
						</div>
						
						
						<div className="col">
							<div className="row">
								<Spacer />
								<div className="col col-70" ref={fade6}>
									<TextBlock>
										<h2>How do you feel knowing you are helping to build the world’s largest offshore wind farm?</h2>
										<p>I love the challenge, it’s a lot of responsibility. For me to go out and survey the work knowing five years later people will be looking at my drawings with my name on them is a big achievement and motivator. </p>
									</TextBlock>
								</div>
								<Spacer />
							</div>

							<div className="row desktop">
								<div className="col" style={imageStyles.image6} ref={image6}>
									<Image src="/images/mustafa/006.jpg" alt="Mustafa Hamed" paddedBottom/>
								</div>
								<div className="col mustafa6" ref={fade7}>
									<PhotoCredit position="Left">Installation of 30km of onshore cable</PhotoCredit>
								</div>
							</div>
							
						</div>


						<div className="col">
							<div className="row mustafa7" style={imageStyles.image7}>
								<div ref={image7}>
									<Image src="/images/mustafa/007.jpg" alt="Mustafa Hamed"/>
								</div>
							</div>
							
							<div className="row">
								<div className="col col-fill col-center" ref={quote0}>
									<Quote author="MUSTAFA HAMED" active={quotes[0]}>
										I love the challenge, it’s a lot <br/>of responsibility
									</Quote>
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col" ref={fade8}>
							<div className="row padded-mobile-sides" style={imageStyles.image8}>
								<TextBlock>
									<h2>How important do you consider the work you are doing?</h2>
									<p>The work I am doing is very important. There are well publicised goals and aims for sustainability and the project I am working on links directly into those. I love that everything I do has an impact. In the future I want to run my own job like this.</p>
								</TextBlock>
							</div>
							
							<div className="row">
								<div className="col padded-normal-bottom margin-mobile-top margin-mobile-bottom" style={imageStyles.image8} ref={image8}>
									<Image src="/images/mustafa/008.jpg" alt="Mustafa Hamed"/>
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col">
							<div className="row mobile-order-2" ref={image9}>
								<div className="mustafa9" style={imageStyles.image9}>
									<Image src="/images/mustafa/009.jpg" alt="Mustafa Hamed"/>
								</div>
							</div>
							
							<div className="row mobile-order-1" ref={fade9}>
								<div className="col padded-mobile-sides margin-mobile-bottom" style={imageStyles.image9}>
									<TextBlock>
										<h2>Why are young people moving into the renewable sector?</h2>
										<p>Young people are going into the renewable sector because this is our future. The world is changing day by day, especially the renewable energy side of things. </p>
										<p>Making the world a greener place is better for the environment and will mean a better, brighter future. I hope to be on another renewable energy project once Dogger Bank is completed.</p>
									</TextBlock>
								</div>
							</div>
						</div>

						<Spacer />

						<div className="col" ref={quote1}>
							<Quote author="MUSTAFA HAMED" active={quotes[1]}>
								Making the world a greener <br/>
								place is better for the <br/>
								environment and will mean a <br/>
								better, brighter future
							</Quote>
						</div>

						<Spacer desktop />

						<div className="col" style={imageStyles.image10} ref={image10}>
							<Image src="/images/mustafa/010.jpg" alt="Mustafa Hamed"/>
						</div>

						<span ref={thankRef}>
							<ThankYou author="Mustafa" active={thanks} />
						</span>

					</div>


					{/* Content */}



				<div className="progress">
					<div className="progress-bar" ref={progressBar}></div>
				</div>
			</div>
		</section>



		
	)
}


export default Apprentice

