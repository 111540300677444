import React from "react"
import styles from "./menu-mobile.module.scss"

const titles = ["the geologist", "the apprentice", "the inventor", "the volunteer"];
const images = ["/images/mobile-geologist.jpg", "/images/mobile-apprentice.jpg" , "/images/mobile-inventor.jpg", "/images/mobile-volunteer.jpg"];

const MenuMobile = ({active, onClick}) => {

	return (
		titles.map((item, index) => {
			
			if(active === index){
				return null;
			}

			return (
				<div className="mobile" key={item} onClick={()=>{onClick(index)}} onKeyPress={()=>{}} aria-hidden="true" role="menuitem">
					<div className={styles.mbbwrapper}>
						<div className={styles.mbimageband}>
							<img className={styles.mbimage} src={images[index]} alt={item} />
							<div className={styles.mbioverlay}></div>
							<div className={styles.mbtitlemobile}>{item}</div>
						</div>
					</div>
					<div className={styles.mbidiv}></div>
				</div>
			)
		})
	)
}

export default MenuMobile;

