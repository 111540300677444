import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import JobTitle from "../job-title/job-title";
import PersonName from "../person-name/person-name";
import PhotoCredit from "../photo-credit/photo-credit";
import Quote from "../quote/quote";
import ThankYou from "../thankyou/thankyou";
import Image from "../image/image";
import { getSizeFor } from "../../globals/ImageSize";
import TextBlock from "../text-block/text-block";
import {gsap, Power1, Power0} from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import './geologist.scss';
import ArrowScroll from "../arrow-scroll/arrow-scroll";
import Spacer from "../spacer/spacer";
import {useWindowWidth} from '@react-hook/window-size'
import useSize from '../../hooks/useSize';

gsap.registerPlugin(ScrollTrigger);


const Geologist = ({active}) => {

	/**
	 * 
	 * This should be a HOC as base, wrapper, progress are the same across all 4 sections
	 * as well as all refs, unfortunately there is no time to develop and debug it :()
	 */

	const base = useRef();
	const wrapper = useRef(null);
	const progressBar = useRef();
	const timeline = useRef();
	const sectionScrollBar = useRef();

	const width = useWindowWidth()

	const intro = useRef();
	const thankRef = useRef();
	
	const fade1 = useRef();
	const fade2 = useRef();
	const fade3 = useRef();
	const fade4 = useRef();
	const fade5 = useRef();
	const fade6 = useRef();
	const fade7 = useRef();
	const fade8 = useRef();
	const fade9 = useRef();
	const fadeImage3 = useRef();

	const image1 = useRef();
	const image2 = useRef();
	const image3 = useRef();
	const image4 = useRef();
	const image5 = useRef();
	const image7 = useRef();
	const image8 = useRef();
	const image9 = useRef();
	const image10 = useRef();

	const quote0 = useRef();
	const quote1 = useRef();
	const quote2 = useRef();
	const [quotes, setQuotes] = useState([])
	const [thanks, setThanks] = useState(false)

	const onActiveQuote = useCallback((index) => {
		const q = [...quotes];
		q[index] = true;
		setQuotes(q);
	}, [quotes])

	const [imageStyles, setStyles] = useState({});
	useEffect(() => {
		let styles = {}

		if(width >= 1100) {
			styles = {
				image1: {width:getSizeFor(1780, 1562, 1).width},
				image2: {width:getSizeFor(1134, 757, 0.5).width, height:getSizeFor(1134, 757, 0.5).height-10},
				image3: {width:getSizeFor(1102, 735, 0.5).width},
				image4: {width:getSizeFor(1151, 768, 0.5).width},
				image6: {width:getSizeFor(2131, 1394, 1).width-80},
				image7: {width:getSizeFor(1472, 982, 0.65).width},
				image7A: { width: getSizeFor(1472, 982, 0.65).width, height: getSizeFor(1472, 982, 0.65).height },
				image9: {width: getSizeFor(1067, 697, 0.5).width-20, height: getSizeFor(1067, 697, 0.5).height-20},
				image10: {width: getSizeFor(1068, 698, 0.5).width-20, height: getSizeFor(1068, 698, 0.5).height-20},
				image11: {width:getSizeFor(2548, 1578, 1).width},
			}
		}

		setStyles(styles);
	}, [width])


	const size = useSize(wrapper);

	useEffect(() => {
		if(!wrapper.current) return;

		if(timeline.current && !active && size && width){

			// const tlContent = gsap.timeline();
			// tlContent.to(wrapper.current, {alpha: 1, duration: 0.70, ease: Power0.easeOut});
			// tlContent.add(() => {
			// 	sectionScrollBar.current.scrollTo(0, 0);
			// }, "+=0.1")
			// tlContent.to(wrapper.current, {alpha: 1, duration: FADE_DURATION, ease: FADE_EASE}, FADE_DELAY)
			
			const x = sectionScrollBar.current.scrollLeft;
			const w = size.width - width;
			const p = x / w;
			const t = (5000 * p) + 500;
			sectionScrollBar.current.scrollTo(0, 0, t);
			
			setQuotes([]);
		}
	}, [active, wrapper, size, width]);
	
	useLayoutEffect(() => {
		if(!size || !timeline.current) return;
		// console.log('size :>> ', size);	
		// timeline.current.scrollTrigger
		ScrollTrigger.refresh(true);
	}, [size])


	useLayoutEffect(() => {
		if(!imageStyles.image1 || timeline.current) {
			return;
		}
		
		sectionScrollBar.current = Scrollbar.init(base.current,
			{	damping: 0.15,
				overscrollEffect: 'bounce',
				// delegateTo: document
			});
		sectionScrollBar.current.setPosition(0, 0);
		sectionScrollBar.current.track.yAxis.element.remove();

		ScrollTrigger.scrollerProxy(base.current, {
			scrollLeft(value) {
				// console.log(`🚀 ~ file: base-section.js ~ line 58 ~ scrollLeft ~ value`, sectionScrollBar.current.scrollLeft);
				
                if (arguments.length) {
					sectionScrollBar.current.scrollLeft = value;
				}
				return sectionScrollBar.current.scrollLeft;
			}
		});
		

		timeline.current = gsap.timeline({
			scrollTrigger: {
				scroller: base.current,
				trigger: wrapper.current,
				horizontal: true,
				scrub: 0,
				// pin: true,
				// markers: true,
				start: "0",
				end: "right right",
				onUpdate: ({progress, direction, isActive}) => {
					// console.log(progress, direction, isActive)
				}
			}
		}).to(progressBar.current, {scaleX: 1, ease: "none"}, 0);

		
		gsap.from(intro.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: intro.current,
				toggleActions: "restart none none reverse",
				start: "left 90%",
				// markers: true,
				horizontal: true,
				// scrub: 1
			},
			duration: 1.05,
			alpha: 0,
			ease: Power0.easeOut
		});
		
		gsap.from(image1.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: image1.current,
				toggleActions: "restart none none reverse",
				start: "left 90%",
				// markers: true,
				horizontal: true,
				// scrub: 1
			},
			duration: 1.05,
			alpha: 0,
			ease: Power0.easeOut
		});

		const fadeins = [fade1.current, fade2.current, fade3.current, fade4.current, fade5.current, fade6.current, fade7.current, fade8.current, fade9.current, fadeImage3.current];
		fadeins.forEach(item => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 75%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				ease: Power1.easeOut
			});
		});
		
		const slides = [image3.current, image8.current, image9.current, image10.current];
		slides.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				x: 100,
				ease: Power1.easeOut
			});
		});

		const slideTop = [image2.current, image4.current, image7.current];
		slideTop.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 90%",
					// markers: true,
					horizontal: true,
					// scrub: 1
				},
				duration: 1.25,
				alpha: 0, 
				y: -30,
				ease: Power1.easeOut
			});
		});

		gsap.from(image5.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: image5.current,
				toggleActions: "restart none none reverse",
				start: "left 90%",
				// markers: true,
				horizontal: true,
				// scrub: 1
			},
			duration: 1.25,
			alpha: 0, 
			y: 30,
			ease: Power1.easeOut
		});

		
		

		const quotesArray = [quote0.current, quote1.current, quote2.current];
		quotesArray.forEach((item, index) => {
			gsap.from(item, {
				scrollTrigger: {
					scroller: base.current,
					trigger: item,
					toggleActions: "restart none none reverse",
					start: "left 60%",
					// markers: true,
					horizontal: true,
					// scrub: 1
					onEnter: ({progress, direction, isActive}) => {
						onActiveQuote(index);
						// console.log(index, progress)
					}
				},
				duration: 0,
			});
		});

		gsap.from(thankRef.current, {
			scrollTrigger: {
				scroller: base.current,
				trigger: thankRef.current,
				toggleActions: "restart none none reverse",
				start: "left 25%",
				horizontal: true,
				onEnter: ({progress, direction, isActive}) => {
					setThanks(true);
				}
			},
			duration: 0,
		});
		

		
		sectionScrollBar.current.addListener(ScrollTrigger.update);

		// if (document.querySelector('.gsap-marker-scroller-start')) {		
		// 	const markers = gsap.utils.toArray('[class *= "gsap-marker"]');	
		
		// 	sectionScrollBar.current.addListener(({ offset }) => {  
		// 		gsap.set(markers, { marginTop: -offset.y })
		// 	});
		// }
		
		return () => {
			setQuotes([])
			timeline.current && timeline.current.kill();
		}

	}, [onActiveQuote, imageStyles])


	

	
	return (

		<section className="base" ref={base}>
			<div className="wrapper" ref={wrapper}>

				{/* Content */}
				<div className="geologist" >
					<Spacer />

					<div className="col col-50 geologist-intro" ref={intro}>
						<PersonName>Hannah Mary Goodlad</PersonName>
						<hr />
						<JobTitle>AREA DEVELOPMENT MANAGER,<br/>BALTIC SEA, EQUINOR</JobTitle>
						<p>Hannah Mary, Equinor’s Area Development Manager for the Baltic Sea is passionate about energy and its effects on progress for society. Transitioning from oil and gas to renewables, Hannah Mary actively uses her geologist background and skillset in her renewables work to creatively solve problems.</p>
						<PhotoCredit>Photography by Sean Pollock</PhotoCredit>
					</div>

					<Spacer />

					<div className="col" style={imageStyles.image1} ref={image1}>
						<Image src="/images/hannah/001.jpg" alt="Hannah Mary Goodlad"/>
						<ArrowScroll left x={-150} />
					</div>

					<Spacer />
					
					<div className="col col-70" ref={fade1}>
						<h2>Did you always want to work in energy?</h2>
						<p>My energy journey started here in the North Sea as development Geologist on the giant Mariner project. I then transitioned into offshore wind operations. I actively use the skillset that Geology provided me every day in my renewables work – the analytical mindset, managing uncertainty and the creativity required to solving problems.</p>
					</div>
					
					<Spacer />

					<div className="col">
						<div className="row">
							<div style={imageStyles.image2} ref={image2}>
								<Image src="/images/hannah/002.jpg" alt="Hannah Mary Goodlad"/>
							</div>
							<div className="flex-mobile mobile">
								<div className="padded-mobile-sides">
									<PhotoCredit position="Above">Hannah Mary at Balmedie Beach, North Aberdeen</PhotoCredit>
								</div>
								<Spacer desktop />
								<div style={imageStyles.image3}>
									<Image src="/images/hannah/003.jpg" alt="Hannah Mary Goodlad"/>
								</div>
							</div>
							<Spacer />
							<div className="col col-70">
								<div ref={fade2}>
									<TextBlock>
										<h2>Can you talk about what needs to happen for the UK to reach net zero?</h2>
										<p>Net zero needs to be a cross-sector, private, public and civic societal effort that will require new levels of global collaboration that society has never experienced before. We need to accelerate the offshore wind revolution within the UK by ensuring a strong, mobilised supply chain that can support local and global developments. Decarbonisation of the “hard to reach” sectors (shipping, aviation, heavy transport) needs a dedicated and targeted plan.</p>

										<span className="mobile">
											<p>Equally, the UK will be required to step up its approach on zero-emission technology, such as carbon capture and storage, and new energy carriers, such as hydrogen.</p>
											<p>For the UK to reach net zero by 2050, this will require a technology transition but this needs to be a “just transition” from a societal angle too. Both in terms of energy access and affordability but also ensuring that good, fair, green jobs are created and sustained.</p>
										</span>
									</TextBlock>
								</div>
							</div>
						</div>
						<Spacer horizontalFill />
						<div className="row desktop">
							<div ref={fadeImage3}>
								<PhotoCredit position="Above">Hannah Mary at Balmedie Beach, North Aberdeen</PhotoCredit>
							</div>
							<Spacer />
							<div style={imageStyles.image3} ref={image3}>
								<Image src="/images/hannah/003.jpg" alt="Hannah Mary Goodlad"/>
							</div>
							<Spacer />
							<div className="col col-70 justify-start" ref={fade3}>
								<p>Equally, the UK will be required to step up its approach on zero-emission technology, such as carbon capture and storage, and new energy carriers, such as hydrogen.</p>
								<p>For the UK to reach net zero by 2050, this will require a technology transition but this needs to be a “just transition” from a societal angle too. Both in terms of energy access and affordability but also ensuring that good, fair, green jobs are created and sustained.</p>
							</div>
						</div>
					</div>

					<Spacer />
					
					<div className="col" ref={quote0}>
						<Quote author="HANNAH MARY GOODLAD" active={quotes[0]}>
							Net zero needs to be a cross-<br/>
							sector, private, public and <br/>
							civic societal effort that will <br/>
							require new levels of global <br/>
							collaboration that society has <br/>
							never experienced before
						</Quote>
					</div>

					<Spacer />

					<div className="col" style={imageStyles.image4}>
						<img src="/images/hannah/004.jpg" alt="Hannah Mary Goodlad" ref={image4}/>
						<img src="/images/hannah/005.jpg" alt="Hannah Mary Goodlad" ref={image5}/>
					</div>

					<Spacer />

					<div className="col col-70">
						<div className="row" ref={fade4}>
							<TextBlock>
								<h2>What do you love about your job?</h2>
								<p>I love my job for several reasons – it’s exciting, challenging, keeps me on my toes, the people I work alongside are deeply passionate and no day is the same. However, the understanding that I am helping to shape the future of energy for my generation and those that will come after me, is the reason I get up in the morning! It’s a huge privilege, a deep responsibility and comes from a genuine love for what I do.</p>
							</TextBlock>
						</div>
						<div className="row mobile-offset padded-mobile-top">
							<div className="hannah-quote1" ref={quote1}>
								<Quote author="Hannah Mary Goodlad" active={quotes[1]}>
									Geology is fundamental to <br/>
									the energy transition and the <br/>
									meeting of our future energy <br/>
									demands
								</Quote>
							</div>
						</div>
					</div>

					<Spacer />

					<div className="col" style={imageStyles.image6} ref={fade8}>
						<Image src="/images/hannah/006.jpg" alt="Hannah Mary" paddedTop paddedBottom/>
					</div>

					<Spacer />

					<div className="col col-70" ref={fade5}>
						<TextBlock>
							<h2>How important is being outside and immersed in nature to you?</h2>
							<p>I believe one of the fundamental things that has gone wrong with humanity is our disconnect with nature and our failure to grasp that we are intrinsically linked to our earth, and her to us.</p>
							<p>Being connected with nature, spending time outside and in the wild is part of what it means “to be human” for me.</p>
							<p>I was fortunate enough to study the science of our planet through Geology. Geology is fundamental to the energy transition and the meeting of our future energy demands. Turning our backs on Earth science is not an option; our future depends on people continuing to learn how our planet works!</p>
						</TextBlock>
					</div>

					<Spacer />

					<div className="col" style={imageStyles.image7}>
						<div className="row" style={imageStyles.image7A} ref={image7}>
							<Image src="/images/hannah/007.jpg" alt="Hannah Mary Goodlad" />
						</div>
						<div className="row flex-mobile" ref={image8}>
							<div className="hannah8">
								<Image src="/images/hannah/008.jpg" alt="Hannah Mary Goodlad" />
							</div>
							<div className="padded-left padded-mobile-right">
								<PhotoCredit position="Above & Left">Hannah Mary at Balmedie Beach, North Aberdeen</PhotoCredit>
							</div>
						</div>
					</div>

					<Spacer />

					<div className="col col-70" ref={fade6}>
						<TextBlock>
							<h2>What are your hopes for the future of energy?</h2>
							<p>With a growing population and increasing standard of living, we are consuming our planet to the point of no return. Climate change is one example but not the only one. Technology alone will not save us, sustainable growth alone will not save us. We need to start to accept to have less. </p>
							<p>My hope for the future of energy is centred around rebalancing the energy demand across our globe combined with a more equitable global distribution of welfare increase. Where all can prosper and thrive, but not at the sacrifice of our planet.</p>
						</TextBlock>
					</div>

					<Spacer />


					{/* MOBILE */}
					<div className="col mobile">
						<Quote author="HANNAH MARY GOODLAD">
							We are consuming our planet <br/>to the point of no return
						</Quote>
					</div>

					<div style={imageStyles.image9} className="mobile">
						<Image src="/images/hannah/009.jpg" alt="Hannah Mary Goodlad" paddedTop/>
					</div>
					<div style={imageStyles.image10} className="mobile hannah10-mobile">
						<Image src="/images/hannah/010.jpg" alt="Hannah Mary Goodlad"/>
					</div>
					<div className="mobile">
						<Spacer />
					</div>
					<div className="col col-70 mobile">
						<TextBlock>
							<h2>How proud do you feel to be a part of a company that is pushing for a more sustainable future? </h2>
							<p>I believe the role of business has never been more vital. Policies are changing, the times are uncertain with a lot of unpredictability in leadership around the world. All of this puts a demand and a responsibility upon business to step up.</p>
						</TextBlock>
					</div>
					{/* /MOBILE */}


					{/* DESKTOP */}
					<div className="col desktop" ref={quote2}>
						
						<div className="row">
							<Spacer desktop />
							<Spacer desktop />
							<div className="row" ref={image9}>
								<div style={imageStyles.image9}>
									<Image src="/images/hannah/009.jpg" alt="Hannah Mary Goodlad" paddedTop/>
								</div>
							</div>
							<Spacer />
							<div className="col col-70" ref={fade7}>
								<TextBlock>
									<h2>How proud do you feel to be a part of a company that is pushing for a more sustainable future? </h2>
									<p>I believe the role of business has never been more vital. Policies are changing, the times are uncertain with a lot of unpredictability in leadership around the world. All of this puts a demand and a responsibility upon business to step up.</p>
								</TextBlock>
							</div>
						</div>
						<Spacer horizontalFill />
						<div className="row">
							<div className="padded-mobile-top">
								<Quote author="HANNAH MARY GOODLAD" active={quotes[2]}>
									We are consuming our planet <br/>to the point of no return
								</Quote>
							</div>
							<Spacer />
							<div style={imageStyles.image10} className="hannah10" ref={image10}>
								<Image src="/images/hannah/010.jpg" alt="Hannah Mary Goodlad" paddedBottom/>
							</div>
						</div>
						
					</div>
					{/* /DESKTOP */}


					<Spacer />

					<div className="col" style={imageStyles.image11} ref={fade9}>
						<Image src="/images/hannah/011.jpg" alt="Hannah Mary Goodlad" />
					</div>

					<span ref={thankRef}>
						<ThankYou author="Hannah Mary" offset={0} active={thanks} />
					</span>

				</div>
				{/* Content */}

				<div className="progress">
					<div className="progress-bar" ref={progressBar}></div>
				</div>
			</div>
		</section>
		
	)
}


export default Geologist



